<template>
  <div class="elv-ledger-auxiliary-code-detail-page">
    <Header
      ref="headerRef"
      type="AUXILIARY_CODE"
      :title="t('menus.ledger')"
      updated-on=""
      :show-regenerate-button="false"
      :show-regenerate="false"
    />

    <HeaderTabs />
    <div v-loading="loading" class="elv-ledger-auxiliary-code-detail-content">
      <AccountBalanceScreening ref="screeningRef" table-type="auxiliaryCode" @onAddNew="onAddNewAuxiliaryType" />

      <TableList
        :table-data="auxiliaryCodeTypeData"
        :table-loading="loading"
        :table-height="tableHeight"
        :auxiliary-code-type-params="auxiliaryTypeParams"
        @onChangePage="onChangePage"
        @onChangePageSize="onChangePageSize"
        @onResetList="getAuxiliaryTypeList()"
        @onEditAuxiliaryCodeType="onEditAuxiliaryCodeType"
      />
      <AuxiliaryTypeFormDialog
        ref="auxiliaryTypeFormDialogRef"
        :model="auxiliaryTypeFormDialogModel"
        :current-data="currentData"
        @onResetList="getAuxiliaryTypeList()"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useI18n } from 'vue-i18n'
import LedgerApi from '@/api/LedgerApi'
import { ElMessage } from 'element-plus'
import Header from '../../components/Header.vue'
import TableList from './components/TableList.vue'
import HeaderTabs from '../components/HeaderTabs.vue'
import { useGlobalStore } from '@/stores/modules/global'
import { useEntityStore } from '@/stores/modules/entity'
import AccountBalanceScreening from '../components/LedgerScreening.vue'
import AuxiliaryTypeFormDialog from '../components/AuxiliaryTypeFormDialog.vue'

dayjs.extend(utc)
const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const globalStore = useGlobalStore()
const loading = ref(false)
const headerRef = ref()
const screeningRef = ref()
const auxiliaryTypeFormDialogRef = ref()
const auxiliaryTypeFormDialogModel = ref('add')
const currentData: any = ref({})

const auxiliaryCodeTypeData: any = ref({
  total: 0,
  list: []
})

const auxiliaryTypeParams = ref({
  page: 1,
  limit: 20
})

const tableHeight = computed(() => {
  const updateProjectTipHeight = globalStore.freeProjectUpdateInfo(entityStore.entityDetail?.projectId) ? '28px' : '0px'
  if (globalStore.showUpGradeInfo) {
    return headerRef.value?.isShowRegeneratedInfo
      ? `calc(100vh - 274px - ${updateProjectTipHeight})`
      : `calc(100vh - 226px - ${updateProjectTipHeight})`
  }
  return headerRef.value?.isShowRegeneratedInfo
    ? `calc(100vh - 322px - ${updateProjectTipHeight})`
    : `calc(100vh - 254px - ${updateProjectTipHeight})`
})

const entityId = computed(() => {
  return String(route.params.entityId)
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const onChangePageSize = (limit: number) => {
  auxiliaryTypeParams.value.limit = limit
  auxiliaryTypeParams.value.page = 1
}

const onChangePage = (page: number) => {
  auxiliaryTypeParams.value.page = page
}

const onAddNewAuxiliaryType = () => {
  if (['MEMBER', ''].includes(currentEntityPermission.value?.role)) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  auxiliaryTypeFormDialogModel.value = 'add'
  auxiliaryTypeFormDialogRef.value?.onCheckAuxiliaryCodeTypeDialog()
}

const onEditAuxiliaryCodeType = (row: any) => {
  auxiliaryTypeFormDialogModel.value = 'edit'
  currentData.value = row
  nextTick(() => {
    auxiliaryTypeFormDialogRef.value?.onCheckAuxiliaryCodeTypeDialog()
  })
}

const getAuxiliaryTypeList = async () => {
  try {
    loading.value = true
    const { data } = await LedgerApi.getAuxiliaryTypeList(entityId.value, auxiliaryTypeParams.value)
    auxiliaryCodeTypeData.value = {
      total: data?.total ?? 0,
      list: data?.list ?? []
    }
  } catch (error) {
    console.error(error)
  } finally {
    loading.value = false
  }
}

watch(
  () => route,
  async () => {
    if (route.name === 'entity-ledger-auxiliary-code') {
      await getAuxiliaryTypeList()
    }
  },
  { immediate: true }
)
</script>
<style lang="scss" scoped>
.elv-ledger-auxiliary-code-detail-page {
  width: 100%;
  height: 100%;
  position: relative;

  .elv-ledger-auxiliary-code-detail-content {
    width: 100%;
    height: calc(100% - 100px);
    position: relative;
  }
}
</style>
